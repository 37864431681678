import {
  Alert,
  BasicInput,
  Container,
  FAB,
  FormikSelect,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { ModuloDTO } from 'common/type';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ModuloService, PermissaoService } from '../../service';
import { Permissao } from '../../type';

type Props = {};

const validationSchema = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  modulo: Yup.string().required('Módulo é obrigatório')
});

const initialValue: Permissao = {
  id: '',
  nome: '',
  descricao: '',
  modulo: ''
};

const PermissaoFormPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [permissao, setPermissao] = useState<Permissao>(initialValue);
  const [modulos, setModulos] = useState<ModuloDTO[]>([]);
  const isEdit = id !== 'new';

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      PermissaoService.findById(id)
        .then(response => setPermissao(response.data))
        .catch(error => {
          Alert.error({ title: `Erro ao buscar a permissão ${id}` }, error);
        })
        .finally(() => setLoading(false));
    }
  }, [id, isEdit]);

  useEffect(() => {
    ModuloService.getModulos()
      .then(response => setModulos(response.data))
      .catch(error => {
        Alert.error({ title: 'Erro ao buscar os módulos' }, error);
      });
  }, []);

  const onSubmit = (values: Permissao) => {
    setLoading(true);
    PermissaoService.save(values)
      .then(() => {
        Alert.success({ title: 'Permissão salva com sucesso' });
        history.goBack();
      })
      .catch(error => {
        Alert.error({ title: 'Erro ao salvar a permissão' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik
        initialValues={permissao}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formProps: FormikProps<Permissao>) => (
          <>
            <Row>
              {modulos.length && (
                <FormikSelect
                  name="modulo"
                  label="Módulo"
                  options={modulos}
                  getOptionLabel={(option: ModuloDTO) => option.descricao}
                  getOptionValue={(option: ModuloDTO) => option.nome}
                  size={6}
                  disabled={isEdit}
                />
              )}
            </Row>
            <Row>
              <BasicInput name="nome" label="Nome" size={6} disabled={isEdit} />
              <BasicInput name="descricao" label="Descrição" size={6} />
            </Row>
            <div className="btn-save">
              <FAB
                icon="check"
                title="Salvar"
                onClick={formProps.submitForm}
                disabled={!formProps.isValid}
              />
            </div>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default PermissaoFormPage;
