import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Permissao } from '../type/Permissao';

const PERMISSAO_API = '/console-api/api/permissoes';

export const findAll = (
  search: string,
  pagination?: PageRequest,
  sort: Sort = { sort: 'modulo,nome,asc' }
): AxiosPromise<PagedResponse<Permissao>> => {
  return axios.get(PERMISSAO_API, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const save = (permissao: Permissao): AxiosPromise<Permissao> =>
  permissao.id
    ? axios.put(`${PERMISSAO_API}/${permissao.id}`, permissao)
    : axios.post(PERMISSAO_API, permissao);

export const findById = (id: string): AxiosPromise<Permissao> =>
  axios.get(`${PERMISSAO_API}/${id}`);
