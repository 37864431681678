const asRoleType = <
  T extends { [K in keyof T]: { name: K; description: string } }
>(
  t: T
) => t;

export const Roles = asRoleType({
  view_cliente: {
    name: 'view_cliente',
    description: 'Permite visualizar clientes.'
  },
  manage_cliente: {
    name: 'manage_cliente',
    description: 'Permite visualizar, cadastrar, editar clientes.'
  },
  clientes_modulo_config: {
    name: 'clientes_modulo_config',
    description: 'Permite configurar módulos dos clientes.'
  },
  clientes_modulo_externo: {
    name: 'clientes_modulo_externo',
    description: 'Permite configurar módulos externos dos clientes.'
  },
  gestao_bloqueio_view: {
    name: 'gestao_bloqueio_view',
    description: 'Permite visualizar gestões de bloqueios.'
  },
  gestao_bloqueio_manage: {
    name: 'gestao_bloqueio_manage',
    description: 'Permite criar e editar gestões de bloqueios.'
  },
  gestao_bloqueio_remove: {
    name: 'gestao_bloqueio_remove',
    description: 'Permite Inativar gestões de bloqueios.'
  },
  manage_cache: {
    name: 'manage_cache',
    description: 'Permite limpar cache.'
  },
  permissao_view: {
    name: 'permissao_view',
    description: 'Permite visualizar permissões.'
  },
  permissao_manage: {
    name: 'permissao_manage',
    description: 'Permite criar e editar permissões.'
  }
});
