import imgModuleNewTheme from '@elotech/arc/src/assets/img/modules/oxy-logo.svg';
import { Sidebar, useAuth } from '@elotech/components';
import React from 'react';

import { Roles } from '../roles/RolesConsole';

type Props = {
  toggledSidebar: boolean;
  showSideBar: boolean;
};

const AppSidebar: React.FC<Props> = props => {
  const { showSideBar, toggledSidebar } = props;
  const { hasRole } = useAuth();
  if (!showSideBar) {
    return null;
  }
  return (
    <Sidebar
      title="Console"
      imgModuleMain={`${process.env.PUBLIC_URL + '/console-small.png'}`}
      imgModule={`${process.env.PUBLIC_URL + '/console-small.png'}`}
      imgModuleNewTheme={imgModuleNewTheme}
      toggledSidebar={toggledSidebar}
    >
      <Sidebar.Item title="Início" to="/" icon="fa fa-home" />

      <Sidebar.Item
        title="Clientes"
        to="/clientes"
        icon="fa fa-users"
        visible={hasRole(Roles.view_cliente.name)}
      />
      <Sidebar.Item
        title="Gestão de Bloqueios"
        to="/gestoes-bloqueios"
        icon="fa fa-lock"
        visible={hasRole(Roles.gestao_bloqueio_view.name)}
      />
      <Sidebar.Item
        title="Permissão"
        to="/permissoes"
        icon="fa fa-shield-alt"
        visible={hasRole(Roles.permissao_view.name)}
      />
      <Sidebar.Item
        title="Cache"
        to="/cache"
        icon="fa fa-eraser"
        visible={hasRole(Roles.manage_cache.name)}
      />
    </Sidebar>
  );
};

export default AppSidebar;
